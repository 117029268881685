$nuvee_thematic                 : var(--nuvee_thematic, nutrition);

$nuvee_background_color         : var(--nuvee_background_color, #FFF);

$nuvee_primary_color            : var(--nuvee_primary_color, #145A79);
$nuvee_secondary_color          : var(--nuvee_secondary_color, #00A3A6);

$nuvee_text_black               : var(--nuvee_text_black, #000000);
$nuvee_text_gray                : var(--nuvee_text_gray, #828282);

$nuvee_secondary_color_default  : var(--nuvee_secondary_color_default, #00A3A6);
$nuvee_secondary_color_1        : var(--nuvee_secondary_color_1, #E6F4F2);
$nuvee_secondary_color_2        : var(--nuvee_secondary_color_2, #DAEAE7);
$nuvee_secondary_color_3        : var(--nuvee_secondary_color_3, #B9D1CC);
$nuvee_secondary_color_4        : var(--nuvee_secondary_color_4, #A1BEB8);
$nuvee_secondary_color_5        : var(--nuvee_secondary_color_5, #607D8B);
$nuvee_secondary_color_6        : var(--nuvee_secondary_color_6, #546D79);
$nuvee_secondary_color_7        : var(--nuvee_secondary_color_7, #D9EAE7);
$nuvee_footer_background_color  : var(--nuvee_footer_background_color, #3E3D3D);

//$answer_right_color             : var(--answer_right_color, #00A3A6);
$answer_right_color             : var(--answer_right_color, #0DA642);
//$answer_wrong_color             : var(--answer_wrong_color, #F29086);
$answer_wrong_color             : var(--answer_wrong_color, #E73439);
$answer_right_deep_color        : var(--answer_right_deep_color, #145A79);
$answer_right_smooth_color      : var(--answer_right_smooth_color, #00A3A6);
$answer_wrong_deep_color        : var(--answer_wrong_deep_color, #a7584f);
$answer_wrong_smooth_color      : var(--answer_wrong_smooth_color, #F29086);
$answer_background_lightblue    : var(--answer_background_lightblue, #f5fffd);

$image_width: var( --image_width, 140px );
$image_height: var( --image_height, 120px );

$image_width_mini: var( --image_width_mini, 80px );
$image_height_mini: var( --image_height_mini, 80px );

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Thin.ttf");
    font-weight: 100;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat/Montserrat-Bold.ttf");
    font-weight: bold;
}



.nutrition{
    --nuvee_thematic: nutrition;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#00A3A6;
    --nuvee_secondary_color_1 : #E6F4F2;
    --nuvee_secondary_color_2 : #DAEAE7;
    --nuvee_secondary_color_3 : #B9D1CC;
    --nuvee_secondary_color_4 : #A1BEB8;
    --nuvee_secondary_color_5 : #607D8B;
    --nuvee_secondary_color_6 : #546D79;
    --nuvee_secondary_color_7 : #D9EAE7;

    --nuvee_footer_background_color: #3E3D3D;
}

.chirurgie{
    --nuvee_thematic: chirurgie;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#F29086;
    --nuvee_secondary_color_1 : #F8EDEC;
    --nuvee_secondary_color_2 : #F7E3E1;
    --nuvee_secondary_color_3 : #DDC8C6;
    --nuvee_secondary_color_4 : #DBBBB8;
    --nuvee_secondary_color_5 : #C4A5A2;
    --nuvee_secondary_color_6 : #8E7A78;
    --nuvee_secondary_color_7 : #F7E3E1;

    --nuvee_footer_background_color: #3E3D3D;
}

.maternite{
    --nuvee_thematic: maternite;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#C24191;
    --nuvee_secondary_color_1 : #F7EDF4;
    --nuvee_secondary_color_2 : #E0BFD3;
    --nuvee_secondary_color_3 : #DEC5D5;
    --nuvee_secondary_color_4 : #DBBBCF;
    --nuvee_secondary_color_5 : #CCA9BF;
    --nuvee_secondary_color_6 : #84737E;
    --nuvee_secondary_color_7 : #F8ECF4;

    --nuvee_footer_background_color: #3E3D3D;
}

.diabete{
    --nuvee_thematic: diabete;
    --nuvee_background_color:#FFF;
    --nuvee_primary_color: #145A79;
    --nuvee_secondary_color: #00A3A6;

    --nuvee_secondary_color_default:#E73439;
    --nuvee_secondary_color_1 : #FAE8E9;
    --nuvee_secondary_color_2 : #E5C2C3;
    --nuvee_secondary_color_3 : #E2C2C3;
    --nuvee_secondary_color_4 : #E0AEAF;
    --nuvee_secondary_color_5 : #E0AEAF;
    --nuvee_secondary_color_6 : #6E5C5C;
    --nuvee_secondary_color_7 : #FAE8E9;
    
    --nuvee_footer_background_color: #3E3D3D;
}

.defaultNuveeButton{
    border-radius: 10px;
    border: none;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600; 
    background-color: $nuvee_primary_color;
    transition: all 300ms;
    &:hover {
        color: $nuvee_primary_color;
        background-color: white;
        box-shadow: 0 0 10px 1px silver;
        cursor: pointer;
    }

    &:active, &:focus{
        background-color: #145A79;
        color: white;
    }
}

.defaultNuveeInput{
    border: #CECACA solid 1px;
    border-radius: 5px;
    background-color: #F7F8F8;
    padding: 5px 10px;
}

.secondaryNuveeInput{
    border: $nuvee_secondary_color_default solid 1px;
    background-color: white;
    padding: 5px 10px;
    color: $nuvee_text_gray;
}

// Styling of the NuveeInfoHint modal
@mixin nuveeInfoHintModalstyle {
    padding: 1.5rem;
    width: 90%;
    max-width: 600px;
    background-color: $nuvee_background_color;
    border: 2px solid #d9d6d6;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:focus {
        outline: none;
    }

    & > div[class*="contentContainer"], // Special class to target the .contentContainer class if rendered in a Portal (outside its module)
    .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.text {
            button {
                margin-top: 1rem;
                border-radius: 9999px;
                font-size: 18px;
                font-weight: 600;
                @include mainPopupButton;
            }
        }

        &.popup {
            .title {
                display: flex;
                align-items: center;
                gap: 1rem;
                width: 100%;

                h2 {
                    font-size: 18px;
                    font-weight: 700;
                }

                img {
                    max-width: 30px;
                    max-height: 30px;
                    height: auto;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: 0.5rem;
                justify-content: flex-start;
                margin-top: 1rem; // Using margin instead of flex gap to have 'custom' spacing

                a {
                    color: var(--accent-color);
                    text-decoration: underline;
                }

                &, p, li, a, span, strong, br, b, i {
                    width: 100%;
                    max-width: 100%;
                    white-space: break-spaces;
                }
            }

            .actionsContainer {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: stretch;
                margin-top: 1rem;
                width: fit-content;

                button {
                    border: none;
                    border-radius: 9999px;

                    &:first-child {
                        @include mainPopupButton;
                    }

                    &:nth-child(2) {
                        padding: 0.1rem 1rem;
                        background-color: $nuvee_background_color;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: color-mix(in srgb, $nuvee_background_color, black 5%);
                        }
                    }
                }
            }
        }

        p {
            margin: 0;
        }
    }

    & > div[class*="dismissCross"], // Special class to target the .dismissCross class if rendered
    .dismissCross {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 0 1rem 0 1rem;

        svg {
            $icon-size: 20px;
            width: $icon-size;
            height: $icon-size;
        }
    }
}

// Styling of the main button in the NuveeInfoHint modal
@mixin mainPopupButton {
    padding: 0.3rem 1.5rem;
    background-color: var(--accent-color);
    border: 2px solid var(--accent-color);
    color: white;
    font-weight: 600;
    transition:
    background-color 0.3s,
    color 0.3s;

    &:hover {
        background-color: color-mix(in srgb, var(--accent-color), transparent 80%);
        color: initial;
    }
}

// Include the style in the NuveeInfoHint component, if it is rendered in a Portal (outside its module)
#nihModalWrapper {
    @include nuveeInfoHintModalstyle;
}