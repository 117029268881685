// Page container

$thema-color-1: #145A79;
$thema-color-2: #00BDFF;

.SubscribeThematics {
    font-weight: 500; // Global base weight

    display: flex;
    flex-direction: row;
    gap: 3rem;

    width: 100%;
    height: 100%;
    padding: 0 2rem;

    p {
        margin: 0;
    }

    button {
        border: none;
    }

    .backButton {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
        padding: 6px 12px;
        background-color: $thema-color-1;
        color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px #00000040;

        font-size: 1.125em;
        text-decoration: none;

        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: color-mix(in srgb, $thema-color-1, white 10%);
        }
    }

    // Thematic selection container
    .thematics {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem 1rem;

        & > h1 {
            font-size: 2em;
            font-weight: 600;
            color: $thema-color-1;
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: $thema-color-1;
                margin-top: 10px;
            }
        }

        // Each collapsable group of thematics
        .thematicGroup {
            display: flex;
            flex-direction: column;

            padding: 1rem;
            border: 1px solid $thema-color-1;
            border-radius: 20px;

            &.isOpen {
                .header .openIcon {
                    transform: rotate(180deg);
                }

                .content {
                    max-height: 600px;
                }
            }
            .header {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                width: 100%;
                margin: 0 1rem;
                font-size: 1.5em;
                color: $thema-color-1;
                cursor: pointer;

                .openIcon {
                    transform: rotate(0);
                    transition: transform 0.3s ease-in-out;
                }

                .info {
                    height: 1.3em;
                }
            }

            // The thematic container inside the group
            .content {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
                row-gap: 2rem;
                column-gap: 1rem;
                width: 100%;
                max-height: 0;
                overflow: hidden;

                transition: max-height 0.3s ease-in-out;

                // Each individual thematic
                .thematicContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    justify-content: center;
                    align-items: center;
                    margin-top: 1rem;

                    .card {
                        padding: 20px;
                        display: flex;
                        gap: 1.5rem;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid $thema-color-1;
                        border-radius: 20px;
                        box-shadow: 4px 4px 4px 0px #00000040;
                        width: 100%;
                        cursor: pointer;

                        font-size: 1.4em;
                        font-weight: 600;
                        color: $thema-color-1;

                        p {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            min-width: 0;
                        }

                        .info {
                            height: 35px;
                            width: 35px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 2px solid $thema-color-1;
                            border-radius: 50%;
                        }
                    }
                    .chooseButton {
                        color: #00bdff;
                        font-weight: 600;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        input[type="checkbox"] {
                            width: 30px;
                            height: 30px;
                            background-color: white;
                            border-radius: 50%;
                            vertical-align: middle;
                            border: 3px solid #00bdff;
                            appearance: none;
                            -webkit-appearance: none;
                            outline: none;
                            cursor: pointer;
                            position: relative;

                            &:checked::after {
                                position: absolute;
                                content: "";
                                width: 20px;
                                height: 20px;
                                background-color: $thema-color-2;
                                border-radius: 50%;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: $thema-color-1;
        }
    }

    .cart {
        width: 380px;
        padding: 35px;
        background-color: color-mix(in srgb, black 10%, transparent);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $thema-color-1;
        
        // Set max width, to get correct Mui Skeleton styles
        p, li {
            width: 100%;
        }

        .cartActions {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                width: 100%;
                padding: 0.5em 1.5em;
                background-color: $thema-color-2;
                color: white;
                font-size: 1.25em;
                font-weight: 600;
                border: none;
                border-radius: 15px;
                margin-bottom: 1rem;
                transition: background-color 0.3s ease-in-out;

                &:active, &:focus {
                    background-color: color-mix(in srgb, $thema-color-2, black 20%);
                }

                &:disabled {
                    background-color: #A0A0A0;
                    cursor: not-allowed;
                }
            }

            p {
                // Set the width a bit bigger to avoid the text being too shrinked
                width: 110%;
                line-height: 1.2em;
            }
        }
    }
}

.dialog {
    --font-color: var(--card-color, #{$thema-color-1});

    :global {
        .MuiDialog-container .MuiPaper-root {
            position: relative;
            max-width: min(800px, 94%);
            border-radius: 20px;
            box-shadow: 2px 2px 4px 0px #00000040;
        }
    }

    .dialogHeader {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        align-items: center;
        gap: 1rem;
        color: var(--font-color);
        font-size: 1.5em;
        font-weight: 700;
        margin-right: 1rem; // Space for the close button

        img {
            max-width: 50px;
            max-height: 50px;
            height: auto;
        }

        .closeButton {
            position: absolute;
            top: 10px;
            right: 10px;
            color: $thema-color-1;
        }
    }
    .dialogContent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        div {
            h2 {
                font-size: 20px;
                font-weight: 600;
                color: var(--font-color);
                margin: 0;
            }
            p {
                font-size: 20px;
                margin: 0;
            }
        }
    }
    .dialogActions {
        display: flex;
        justify-content: center;
        padding-bottom: 24px; // Same padding as Mui horizontal DialogContent padding

        button {
            padding: 4px;
            min-width: 150px;
            background-color: var(--font-color);
            color: white;
            font-size: 1.125em;
            font-weight: 600;
            border: none;
            border-radius: 999px;
        }
    }
}

/** Mobile. Not 768px, because from 900px this style is better. */
@media (max-width: 900px) {
    .SubscribeThematics {
        flex-direction: column;
        gap: 2rem;
        padding: 0;
        font-size: 10px;

        .thematics {
            gap: 1rem;
            padding: 1rem;

            .thematicGroup {
                .header {
                    gap: 1rem;
                    svg {
                        font-size: 1.5em;
                    }
                    .info {
                        height: 1.5em;
                    }
                }

                &.isOpen .content {
                    max-height: 1000px; // Adjust to fit the content, for smoother animations
                }

                .content {
                    row-gap: 0;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    mask: linear-gradient( #fff0, #fff 5%), linear-gradient( #fff 95%, #0000 100%);
                    mask-composite: intersect;

                    .thematicContainer {
                        flex-direction: row-reverse;
                        gap: 2rem;

                        .card {
                            // Reverse the order, and set to start (reversed end)
                            flex-direction: row-reverse;
                            justify-content: flex-end;
                            padding: 10px;
                            border-radius: 99px;
                            gap: 1rem;
                            margin-right: 2rem;
                            min-width: 0;

                            .info {
                                height: 30px;
                                width: 30px;
                                border-width: 1px;
                                font-size: 1.3em;
                            }
                        }
                        .chooseButton {
                            font-size: 1.2em;

                            p {
                                display: none;
                            }

                            input[type="checkbox"] {
                                width: 32px;
                                height: 32px;
                                border-width: 2px;

                                /** Style for checkbox. Unchecked is a cross, check a circle */
                                &::before, &::after {
                                    --thickness: 3px;
                                    position: absolute;
                                    content: "";
                                    border-radius: var(--thickness);
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    background-color: $thema-color-2;
                                    transition: all 0.1s ease-in-out;
                                }

                                &::before {
                                    width: 14px;
                                    height: var(--thickness);
                                }

                                &::after {
                                    width: var(--thickness);
                                    height: 14px;
                                }

                                &:checked::after {
                                    width: 17px;
                                    height: 17px;
                                }
                            }
                        }

                        &:last-of-type {
                            margin-bottom: 0.5rem;
                        }
                    }
                }
            }
        }

        .cart {
            width: 100%;
            min-height: 150px;
            padding: 15px;

            .cartActions {
                button {
                    width: fit-content;
                }
                p {
                    max-width: 70%;
                    font-size: 1.2em;
                }
            }
        }
    }

    .dialog {
        .dialogHeader {
            font-size: 1.2em;
            img {
                max-width: 40px;
                max-height: 40px;
            }

            .closeButton {
                font-size: 1.5em;
            }
        }
        .dialogContent {
            div {
                h2 {
                    font-size: 16px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        .dialogActions {
            button {
                font-size: 1em;
            }
        }
    }
}
