$color-primary: #145A79;
$color-secondary: #155A7980;
$color_background: #FFF;
$box-shadow-default: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
$font-size-large: 24px;
$font-size-medium: 20px;
$font-size-small: 14px;
$font-weight-bold: 700;
$font-weight-semi: 500;
$border-radius: 12px;

@mixin flex-center($justify: center, $align: center, $gap: 0) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin font($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

.layoutContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 500px;
  padding: 24px;
  gap: 24px;

  .item {
    padding: 16px;
  }

  .summaryCard {
    width: 100%;
    background-color: $color_background;
    color: $color-primary;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;
    height: fit-content;

    .cardPart {
      border-top: 1px solid $color-secondary;
    }

    .title {
      @include font($font-size-large, $font-weight-bold, inherit);
      width: 100%;
      text-align: center;
    }

    .line {
      @include flex-center(space-between, flex-start);
      margin: 8px 4px;
      @include font(16px, 700, inherit);
    }

    .list {
      text-align: right;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .price {
      @include font($font-size-large, $font-weight-bold, inherit);
    }

    .subtitle {
      @include font($font-size-medium, $font-weight-bold, inherit);

      span {
        @include font($font-size-small, $font-weight-semi, $color-secondary);
      }
    }

    .imgContainer {
      width: 100%;
      padding: 12px;
      @include flex-center(flex-start, center, 20px);

      img {
        width: 100%;
        max-width: 60px;
        height: auto;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .layoutContainer {
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 20px;
  }

  .summaryCard {
    grid-column: 1 / -1;
  }
}

@media (max-width: 768px) {
  .layoutContainer {
    grid-template-columns: 1fr;
    padding: 16px;
    gap: 16px;
  }

  .summaryCard {
    display: none;
  }
}