$color-primary: #145A79;
$color-secondary: #00BDFF;
$color-background: #fff;
$color-text: #868686;
$color-text-secondary: #333;
$color-error: #e73439;
$font-size-large: 24px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-weight-base: 600;
$font-weight-regular: 400;
$border-radius: 4px;
$border-radius-large: 12px;
$spacing-base: 16px;

@mixin flex($direction: row, $justify: flex-start, $align: stretch, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin transition($properties: all, $duration: 0.3s, $timing: ease) {
  transition: $properties $duration $timing;
}

.dialog {
  :global {
      .MuiDialog-container .MuiPaper-root {
        position: relative;
        max-width: min(800px, 94%);
        border-radius: 20px;
        box-shadow: 2px 2px 4px 0px #00000040;
      }
    }
    font-family: Montserrat, sans-serif;
    
  .dialogHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $color-primary;
    font-size: 1.5em;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    margin-right: 1rem;

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $color-primary;
    }
  }

  .dialogContent {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    
    p {
      margin: 0;
    }

    span {
      font-weight: 600;
      color: $color-primary;
    }

    .quote {
      p {
        margin: 4px;
      }

      border-left: 4px solid #ccc;
      padding-left: 10px;
      margin: 10px 0;
      color: #555;
      background-color: #f9f9f9;
      border-radius: 4px;
    }
  }

  .dialogFooter {
    padding: 1rem;

    .button {
      background-color: $color-primary;
      color: #fff;
      border: none;
      border-radius: 12px;
      padding: 8px 16px;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      margin: auto;
      
      &:hover {
        background-color: darken($color-primary, 10%);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}

.checkboxLabel {
  @include flex(row, flex-start, center, 8px);
  font-weight: $font-weight-regular;
  color: $color-text-secondary;
  cursor: pointer;
  user-select: none;
  position: relative;

  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus+.customCheckbox {
      box-shadow: 0 0 0 2px lighten($color-primary, 20%);
    }
  }

  .customCheckbox {
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    transition: border 0.3s ease, background-color 0.3s ease;
    box-sizing: border-box;
    flex-shrink: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: none;
      background-color: $color-primary;
    }

    &::after {
      width: 2px;
      height: 10px;
      left: 8px;
      top: 2px;
      transform: rotate(45deg);
    }

    &::before {
      width: 2px;
      height: 5px;
      left: 3px;
      top: 8px;
      transform: rotate(-45deg);
    }
  }

  .checkbox:checked+.customCheckbox::before,
  .checkbox:checked+.customCheckbox::after {
    display: block;
  }

  &.errorLabel {
    color: $color-error;

    .customCheckbox {
      border-color: $color-error;

      .checkbox:checked+.customCheckbox::after {
        border-color: $color-error;
      }
    }
  }

  .highlight {
    font-weight: 700;
    color: $color-primary
  }

  &.errorLabel {
    color: $color-error;

    .highlight {
      color: $color-error;
    }
  }
}
  
.informationsForm {
  @include flex(column, flex-start, flex-start, $spacing-base);
  width: 100%;
  max-width: 1200px;
  margin: auto;


  .title {
    width: 100%;
    font-size: $font-size-large;
    font-weight: $font-weight-base;
    color: $color-primary;
    margin-bottom: $spacing-base;
    border-bottom: 1px solid $color-primary;
  }

  .group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    
    .field {
      display: flex;
      flex-direction: column;
      flex: 1 1 calc(33.333% - 16px);
      min-width: 200px;
      max-width: 500px;
      width: 100%;
      margin-bottom: $spacing-base / 2;

      &.fullWidth {
        flex: 1 1 100%;
        max-width: none;
      }

      .inputContainer {
        position: relative;
        margin-bottom: $spacing-base / 2;

        .input,
        .select {
          width: 100%;
          padding: 12px;
          border: 1px solid #ccc;
          border-radius: $border-radius;
          font-size: $font-size-base;
          background-color: transparent;
      
          &:focus {
            border-color: $color-primary;
            outline: none;
          }

          &:focus + .label {
            color: $color-primary;
          }
      
          &.filled + .label,
          &:focus + .label {
            top: 0;
            left: 8px;
            font-size: $font-size-small;
            background-color: $color-background;

            &.errorLabel {
              color: $color-error;
            }
          }

          &.errorInput {
            border-color: $color-error;
          }
        }
        
        .label {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translateY(-50%);
          background-color: $color-background;
          padding: 0 4px;
          color: $color-text;
          font-size: $font-size-base;
          font-weight: $font-weight-base;
          pointer-events: none;
          transition: all 0.2s ease-out;

          &.errorLabel {
            color: $color-error;
          }
        }
        
        .select {
          appearance: none;
          background-color: #fff;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5'%3E%3Cpolygon points='0,0 10,0 5,5' fill='%23868686'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 12px center;
          background-size: 10px 5px;
          color: $color-text;
          cursor: pointer;
          padding-right: 30px;

          option {
            color: $color-text-secondary;
            background-color: $color-background;
            padding: 8px 12px;

            &:hover {
              background-color: rgba($color-primary, 0.1);
              color: #fff;
            }
          }

          &.errorInput {
            border-color: $color-error;
          }
        }

        .loader {
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      
      .errorMessage {
        color: $color-error;
        font-size: $font-size-small;
        margin-top: 4px;
      }

      .suggestionsList {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1000;
        list-style: none;
        margin: 0;
        padding: 0;

        .suggestion {
          padding: 10px 12px;
          cursor: pointer;
          font-size: 14px;
          border-bottom: 1px solid #eee;
          transition: background-color 0.2s;
          
          &:last-child {
            border-bottom: none;
          }

          &:hover,
          &:active {
            background-color: #f0f0f0;
          }
        }
      }
    }

    .field:only-child {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  .checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .errorMessage {
    color: $color-error;
    font-size: $font-size-small;
    margin-top: 4px;
  }

  .required {
    color: $color-error;
  }

  .submitButton {
    margin: auto;
    padding: 12px 24px;
    background-color: $color-secondary;
    color: #fff;
    border: none;
    border-radius: $border-radius-large;
    font-size: $font-size-base;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    gap: 12px;
    @include transition(background-color);

    .buttonLoader {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .spinner {
      animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes dash {
      0% {
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dashoffset: -124;
      }
      
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }

    &:not(:disabled) {
      &:hover {
        background-color: darken($color-secondary, 15%);
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(20, 90, 121, 0.5);
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .informationsForm {
    .group {
      @include flex(row, flex-between, stretch, $spacing-base / 2);

      .field {
        flex: 0 0 48%;
        max-width: 48%;
        margin-bottom: $spacing-base / 4;
      }
    }

    .inputContainer {
      margin-bottom: $spacing-base / 4;
    }
  }
}

@media (max-width: 768px) {
  .informationsForm {
    .group {
      flex-direction: column;

      .field {
        flex: 1 1 100%;
        margin: auto;

        &.fullWidth,
        &:only-child {
          max-width: 500px;
        }
      }
    }

    .inputContainer {
      margin-bottom: $spacing-base / 4;
    }

    .submitButton {
      padding: 8px 16px;
    }
  
    .label {
      left: 8px;
      
    }
  
    .input,
    .select {
      padding: 14px 12px;
    }
  }
}