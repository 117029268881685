$thema-color-1: #145a79;
$thema-color-2: #00bdff;

// Set max width, to get correct Mui Skeleton styles
p,
li {
    width: 100%;
}

/** Part of the liberals cart that recaps the offers, and the features in it. 
* Be careful, as it is used in the thematics page, but also in the cart page. */
.cartRecap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 4rem 0;
    text-align: center;

    .title {
        font-size: 1.5em;
        font-weight: 600;
    }

    .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        .titleText {
            font-size: 1.5em;
            font-weight: 700;
        }
        .priceText {
            font-size: 2.5em;
            font-weight: 700;
            color: $thema-color-2;
        }
    }

    .features {
        width: 100%;
        list-style: none;
        text-align: left;

        li {
            display: flex;
            align-items: center;
        }

        li:not(:last-of-type) {
            margin-bottom: 1em;
        }

        li:before {
            content: "";
            display: inline-block;
            width: 1em;
            height: 1em;
            min-width: 1em;
            min-height: 1em;
            border-radius: 50%;
            background-color: $thema-color-2;
            margin: 0 0.5em 0 -1.5em;
        }
    }

    .divider {
        width: 75%;
        height: 2px;
        background-color: $thema-color-1;
        margin: 0.8em 0;
    }

    /** Mobile. Not 768px, because from 900px this style is better. */
    @media (max-width: 900px) {
        & {
            padding: 0;

            .features {
                display: none;
            }

            .divider {
                width: 60%;
                height: 1px;
            }
        }
    }
}

/** Actual cart page */
.cart {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
    padding: 3rem;
    gap: 3rem;

    p {
        margin: 0;
    }

    button {
        border: none;
    }

    /** The cards that contains informations about the product */
    .choiceCard,
    .priceCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 32px 48px 32px;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 2px #00000040;
        width: fit-content;
        gap: 32px;

        // Set the lat card on the right, and the first one in between the left window border and the second card
        margin-left: auto;

        & > h2 {
            font-size: 1.5em;
            font-weight: 600;
            color: $thema-color-1;
            width: 100%;

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: color-mix(in srgb, $thema-color-1, transparent 50%);
                margin-top: 0.2em;
            }
        }
    }

    /** The card that contains the offer chosen by the liberal */
    .choiceCard {
        position: relative;

        .content {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 64px;

            .offerImage {
                max-height: 100%;
                width: 100%;
                max-width: 350px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain; // TODO: check image size when removed placeholder
                }
            }

            .cartRecap {
                height: fit-content;
                padding: 0;
                line-height: 1.2;

                .price {
                    .priceText {
                        font-size: 1.5em;
                    }
                    p:last-of-type {
                        font-size: 0.8em;
                    }
                }

                .features {
                    margin-top: 1rem;
                }

                .thematics {
                    margin-top: 1rem;
                    h2,
                    p {
                        font-size: 1em;
                        font-weight: 600;
                    }

                    h2 {
                        font-style: italic;
                        color: $thema-color-1;
                        font-weight: 500;
                    }
                }
            }

            /** Style of "choice" card when cart is empty */
            &.emptyCart {
                align-items: center;
                .offerImage {
                    max-width: 200px;
                }

                .noProductTexts {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
                    color: $thema-color-1;
                    text-align: center;
                    h2 {
                        font-size: 1.5em;
                        font-weight: 700;
                    }
                    p {
                        font-size: 1em;
                        font-style: italic;
                    }
                    button {
                        font-size: 1em;
                    }
                }
            }
        }

        .previousStep {
            position: absolute;
            bottom: 10px;
            left: 10px;
            color: $thema-color-2;
            font-weight: 700;
            background-color: transparent;
        }

        &.fitHeight {
            height: fit-content;
        }
    }

    /** The card that contains the final calculated price of the offer and action */
    .priceCard {
        .prices {
            width: 100%;
            display: flex;
            flex-direction: column;

            .priceLine {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $thema-color-1;
                max-width: 475px;

                p {
                    font-size: 1.225em;
                    font-weight: 700;
                    width: fit-content;

                    span {
                        font-size: 0.6em;
                        font-weight: 500;
                    }

                    &.totalPrice {
                        color: $thema-color-2;
                        font-size: 1.5em;
                    }
                }
            }
        }

        .checkout {
            width: fit-content;
            padding: 0.5em 1.5em;
            font-size: 1.25em;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        .paymentMethods {
            width: 100%;
            font-size: 1.225em;
            font-weight: 700;
            color: $thema-color-1;

            .paymentLogos {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 1em;
                margin-top: 1em;

                img {
                    max-width: 60px;
                    width: auto;
                    object-fit: contain;
                }
            }
        }
    }
}

/** Default nuvee button style */
button.nuveeButton {
    width: fit-content;
    padding: 0.5em 1.5em;
    background-color: $thema-color-2;
    color: white;
    font-size: 1.25em;
    font-weight: 600;
    border: none;
    border-radius: 15px;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease-in-out;

    &:active,
    &:focus {
        background-color: color-mix(in srgb, $thema-color-2, black 20%);
    }

    &:disabled {
        background-color: #a0a0a0;
        cursor: not-allowed;
    }
}

@media (max-width: 900px) {
    .cart {
        padding: 1rem;
        flex-direction: column;
        gap: 2rem;

        .choiceCard,
        .priceCard {
            width: 100%;
        }

        .choiceCard {
            .content {
                flex-direction: column;
                align-items: center;
                gap: 2rem;
                max-width: 400px;

                .offerImage {
                    max-width: 100%;
                }

                .cartRecap .features {
                    display: block;
                }
            }
        }

        .priceCard {
            .prices {
                width: 100%;
                .priceLine {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

/** Absolute bottom left bubble, used to, on mobile, scroll to the cart div */
.mobileCartHint {
    @media (min-width: 900px) {
        display: none;
    }

    position: fixed;
    display: flex;
    bottom: 1rem;
    right: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    padding: 14px;
    background-color: white;
    box-shadow: 5px 5px 4px 0px #00000040;
    color: $thema-color-1;
    font-size: 26px;
    border: none;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
