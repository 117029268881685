@import "./utils";

.NuveeInfoHint {
    h1,
    h2,
    h3,
    h4,
    p {
        margin: 0;
    }

    $icon-size: var(--nuvee-infohint, 1.5rem);
    height: $icon-size;
    width: $icon-size;

    cursor: pointer;

    &.tooltip {
        & > span {
            width: 100%;
            height: 100%;

            svg {
                width: $icon-size;
                height: $icon-size;
            }
        }
    }

    &.modal {
        & > svg {
            width: $icon-size !important;
            height: $icon-size !important;
        }

        // If you need to style the modal, check the following mixin
        .nihModalWrapper {
            @include nuveeInfoHintModalstyle;
        }
    }
}