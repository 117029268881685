
.scrollableContainer {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.buttonDialog {
  background-color: #00BDFF;
  border-color: #00BDFF;
  text-transform: none;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  color: #FFF;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0099CC;
  }
}
