.header {
  background-color: #145a79;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .title {
    color: #fff;
    height: 34px;
    font-weight: 700;
    text-align: center;
  }
}

.content {
  max-width: 850px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  p {
    color: #145a79;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: justify;

    span {
      font-weight: 700;
    }

    a {
      color: #00BDFF;
      font-weight: 700;
    }
  }
  
  .meeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 64px;
    
    .meetingButton {
      position: relative;
      
      img {
        position: absolute;
        width: 220px;
        top: -80px;
        right: -220px;
        object-fit: contain;
      }

      .button {
        background-color: #00BDFF;
        border-color: #00BDFF;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 8px;
        border: none;
        padding: 8px 12px;
        color: #FFF;
        width: fit-content;
  
        &:hover {
          cursor: pointer;
        }
  
        &:active {
          background-color: darken(#00BDFF, 10%);
          border-color: darken(#00BDFF, 10%);
        }
      }
    }
  }

  .card {
    margin-top: 2rem;
    position: relative;
    background-color: #fff;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    width: calc(100% - 270px);
    margin-left: calc((100% - 270px) / 2);
    border: 1px solid lighten(#145a79, 60%);

    img {
      position: absolute;
      width: 270px;
      top: -50px;
      left: -290px;
      object-fit: contain;
    }

    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 0;
      border-bottom: 1px solid lighten(#145a79, 50%);

      &:last-child {
        border-bottom: none;
      }

      h2 {
        flex: 1;
        color: #145a79;
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        text-align: right;
      }

      span {
        flex: 1;
        color: #323232;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        word-wrap: break-word;
      }
    }
  }
}

@media (max-width: 960px) {
  .content {
    padding: 16px;
    max-width: 750px;

    .card {
      margin: 0.5rem auto;
      width: calc(100% - 190px);
      max-width: 400px;
      margin-left: calc((100% - 190px) / 2);  

      img {
        width: 190px;
        top: 0px;
        left: -200px;
      }

      .line {
        flex-direction: column;

        h2, span {
          font-size: 18px;
        }
      }
    }

    .meeting {
      align-items: center;
      gap: 24px;

      .meetingButton {
        img {
          width: 190px;
          top: -65px;
          right: -220px;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 599px) {
  .header {
    height: 60px;

    .title {
      font-size: 18px;
      height: auto;
      margin: 0;
    }
  }

  .content {
    max-width: 100%;
    padding: 0 1.5rem;
    gap: 1rem;

    .accordion {
      width: 100%;
      max-width: 600px;
      margin-bottom: 1rem;

      .accordionHeader {
        width: 100%;
        background-color: #145a79;
        color: #fff;
        padding: 0.75rem 1rem;
        border: none;
        border-radius: 8px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        transition: background-color 0.3s ease;

        &::before,
        &::after {
          content: "";
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
          width: 1.2rem;
          height: 0.2rem;
          background-color: #fff;
          transition: opacity 0.3s ease, transform 0.3s ease;
        }

        &::before {
          transform: translateY(-50%);
          opacity: 1;
        }

        &::after {
          content: "";
          width: 0.2rem;
          height: 1.2rem;
          background-color: #fff;
          transform: translateY(-50%) translateX(-250%) rotate(0deg);
          opacity: 1;
        }

        
        &.open::before {
          transform: translateY(-50);
          opacity: 1;
        }
        
        &.open::after {
          opacity: 0;
          transform: translateY(-50%) translateX(-0.5rem) rotate(-90deg);
        }

        &:hover {
          background-color: darken(#145a79, 10%);
        }
      }
      
      .accordionContent {
        background-color: #f9f9f9;
        padding: 1rem;
        border: 1px solid #ddd;
        border-top: none;
        border-radius: 0 0 8px 8px;
        animation: fadeIn 0.3s ease;

        p {
          margin: 0;
        }
      }
    }

    p {
      font-size: 14px;
    }

    .card {
      // I need to have this space for the image
      // It's place with absolute position and can be outside the card
      // If we remove this space, the image will be hidden by the footer
      margin: 0 auto 100px auto;
      width: 100%;
      padding: 1rem;
      border-radius: 12px;

      img {
        height: 110px;
        top: unset;
        left: unset;
        right: calc(50% - 150px);
        bottom: -120px;
      }

      .line {
        gap: 0.5rem;
        padding: 0.5rem 0;
        flex-direction: column;

        h2, span {
          font-size: 14px;
        }
      }
    }

    .meeting {
      align-items: flex-start;
      gap: 0.5rem;

      .button {
          background-color: #00BDFF;
          border-color: #00BDFF;
          text-transform: uppercase;
          font-weight: 700;
          padding: 4px 8px;
          font-size: 12px;
        }
    
      img {
        height: 80px;
        top: -25px !important;
        right: -175px !important;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 500px;
  }
}